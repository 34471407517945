<script setup>
/* Props */
const props = defineProps({
  shippingDisclaimerText: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <p
    class="app__product__processing"
    v-html="props.shippingDisclaimerText"
  />
</template>

<style lang="scss">
.app__product__processing {

  a {
    text-decoration: underline;
  }
}
</style>
