<script setup>
/* Imports */
import {
  computed,
  onMounted,
  ref,
  watch,
} from 'vue';

/* Affirm */
import {
  toCents,
  AFFIRM_PRICE_RANGE,
  AFFIRM_SUPPORTED_COUNTRY_CODES,
  AFFIRM_SUPPORTED_LOCALES,
} from '../helpers/affirm/main';

/* Props */
const props = defineProps({
  priceData: {
    type: Object,
    default: () => ({}),
  },
  affirmWidgetSrc: {
    type: String,
    default: '',
  },
  affirmPublicApiKey: {
    type: String,
    default: '',
  },
  setIsAffirmScriptLoaded: {
    type: Function,
    default: () => {},
  },
});

/* State */
const state = ref({
  isSkeletonLoading: true,
});

/* Computed property to calculate priceInCents dynamically */
const priceInCents = computed(() => toCents(props.priceData?.salePrice?.price || props.priceData?.listPrice?.price));

/* Check if the price falls within the Affirm display range */
const isAffirmEligible = computed(() => priceInCents.value >= AFFIRM_PRICE_RANGE.min && priceInCents.value <= AFFIRM_PRICE_RANGE.max);

/* Check if the skeleton loader should be displayed */
const shouldShowSkeleton = computed(() => state.value.isSkeletonLoading && Boolean(shouldDisplayAffirm.value));

/**
 * Check if the Affirm widget should be displayed
 * The Affirm widget should be displayed if:
 * - The price is available
 * - The price falls within the Affirm display range
 */
const shouldDisplayAffirm = computed(() => priceInCents.value && isAffirmEligible.value);

const loadAffirm = () => new Promise((resolve, reject) => {
  const timestamp = new Date().getTime(); // Get the current timestamp to avoid caching
  const AFFIRM_WIDGET_URL = `${props.affirmWidgetSrc}?t=${timestamp}`; // Append the timestamp as a query parameter

  /* eslint-disable */
  window._affirm_config = {
    public_api_key: props.affirmPublicApiKey,
    script: AFFIRM_WIDGET_URL,
    locale: AFFIRM_SUPPORTED_LOCALES[0],
    country_code: AFFIRM_SUPPORTED_COUNTRY_CODES[0],
  };

  (function (l, g, m, e, a, f, b) {
    let d; const
      c = l[m] || {};
    const h = document.createElement(f);
    const n = document.getElementsByTagName(f)[0];
    const k = (a, b, c) => () => a[b]._.push([c, arguments]);

    c[e] = k(c, e, 'set');
    d = c[e];
    c[a] = {};
    c[a]._ = [];
    d._ = [];
    c[a][b] = k(c, a, b);
    [
      'set',
      'add',
      'save',
      'post',
      'open',
      'empty',
      'reset',
      'on',
      'off',
      'trigger',
      'ready',
      'setProduct',
    ]
      .forEach((fn) => d[fn] = k(c, e, fn));
    [
      'get',
      'token',
      'url',
      'items',
    ].forEach((fn) => d[fn] = () => { });

    h.async = true;
    h.src = g[f];
    h.onload = () => {
      resolve(true);
      state.value.isSkeletonLoading = false;
    };
    h.onerror = () => {
      reject(new Error('Failed to load Affirm script'));
      state.value.isSkeletonLoading = false;
    };
    n.parentNode.insertBefore(h, n);

    delete g[f];
    d(g);
    l[m] = c;
  }(window, window._affirm_config, 'affirm', 'checkout', 'ui', 'script', 'ready'));
  /* eslint-enable */
});

/* Lifecycle hooks */
onMounted(async () => {
  try {
    await loadAffirm();
    props.setIsAffirmScriptLoaded(true);
  } catch (error) {
    props.setIsAffirmScriptLoaded(false);
    console.error('Failed to load Affirm onMounted:', error);
  }
});

/**
 * Watches for changes in the `priceData` prop.
 * This function ensures the Affirm widget is reloaded whenever the price data changes.
 * It also manages the skeleton loading state and tracks the loading status of the Affirm script.
 */
watch(() => props.priceData, async () => {
// Set the skeleton loading state to true to show a loading indicator when price data is changing
  state.value.isSkeletonLoading = true;
  try {
    // Reload the Affirm widget with the updated price data
    await loadAffirm();
    // Indicate that the Affirm script has loaded successfully
    props.setIsAffirmScriptLoaded(true);
  } catch (error) {
    console.error('Failed to load Affirm on watcher:', error);
    // If there's an error loading the script, set the loading state to false
    props.setIsAffirmScriptLoaded(false);
  }
});
</script>

<template>
  <template v-if="shouldShowSkeleton">
    <g-skeleton
      class="app__product__skeleton"
      data-testid="affirm-widget-skeleton"
    />
  </template>
  <template v-else-if="shouldDisplayAffirm">
    <div
      class="app__product__affirm"
      data-testid="affirm-widget-container"
    >
      <p
        class="affirm-as-low-as"
        data-page-type="product"
        :data-amount="priceInCents"
        data-testid="affirm-widget"
      />
    </div>
  </template>
</template>

<style lang="scss" scoped>
$margin: -1.5rem 0 0.9375rem 0;

.app__product {

  &__affirm {
    margin: $margin;
    max-width: fit-content;
    min-height: 1.375rem;
  }

  &__skeleton {
    width: 100%;
    height: 2rem;
    margin: $margin;
  }
}
</style>
