<script setup>
/* Props */
const props = defineProps({
  formattedPrice: {
    type: String,
    default: '',
  },
  showPriceLoader: {
    type: Boolean,
    default: false,
  },
  showPriceData: {
    type: Boolean,
    default: false,
  },
  displayedSalePrice: {
    type: String,
    default: '',
  },
  displayedSavings: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <div
    v-if="props.showPriceLoader"
    class="product__info__price__loader-wrapper"
    data-testid="product-info-price-loader"
  >
    <g-loader type="dark" />
  </div>
  <div v-else>
    <g-price
      v-if="props.showPriceData"
      class="product__info__price"
      variant="stacked"
      :original-price="props.formattedPrice"
      :discounted-price="props.displayedSalePrice"
      :savings-text="props.displayedSavings"
    />
  </div>
</template>

<style lang="scss" scoped>
.product__info__price {
  margin: 0.5rem 0 2rem;
  color: $color-black;

  &__loader-wrapper {
    width: 4.6875rem; // 75px
    margin: 1rem 0 2.5rem;
  }

  :deep(.g__price--strike) {

    .savings-text {

      .price-save-text {
        margin-right: 0.25rem;
      }
    }
  }
}
</style>
