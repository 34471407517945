<script>
/* Imports */
import { mapActions, mapGetters } from 'vuex';

/* Components */
import VatTooltip from '../components/VatTooltip.vue';
import ProductAvailability from '../components/ProductAvailability.vue';
import KlarnaWrapper from '../components/KlarnaWrapper.vue';

/* Old Components */
import OldTiles from '../oldComponents/OldTitles.vue';
import OldEmsFilters from '../oldComponents/OldEmsFilters.vue';
import OldKicker from '../oldComponents/OldKicker.vue';
import OldKickerWrapper from '../oldComponents/OldKickerWrapper.vue';
import OlColorPicker from '../oldComponents/OldColorPicker.vue';
import OldPriceWrapper from '../oldComponents/OldPriceWrapper.vue';
import OldKlarnaWidget from '../oldComponents/OldKlarnaWidget.vue';
import OldCtasWrapper from '../oldComponents/OldCtasWrapper.vue';
import OldProductFootnote from '../oldComponents/OldProductFootnote.vue';
import AffirmWidget from '../components/AffirmWidget.vue';

export default {
  name: 'ProductInfo',
  components: {
    OldTiles,
    OldKicker,
    OldKickerWrapper,
    ProductAvailability,
    OldPriceWrapper,
    KlarnaWrapper,
    OldKlarnaWidget,
    OldCtasWrapper,
    OldProductFootnote,
    OlColorPicker,
    OldEmsFilters,
    VatTooltip,
    AffirmWidget,
  },
  data() {
    return {
      priceIsLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      productName: 'getProductName',
      showProductName: 'getShowProductName',
      productVariation: 'getProductVariation',
      showProductVariation: 'getShowProductVariationName',
      partNumber: 'getPartNumber',
      showPartNumber: 'getShowPartNumber',
      showKicker: 'getShowKicker',
      kickerContent: 'getKickerContent',
      kickerText: 'getKickerText',
      loadingPrice: 'getLoadingPrice',
      price: 'getFormattedPrice',
      showPrice: 'getShowPrice',
      showFormattedPrice: 'getShowFormattedPrice',
      salePrice: 'getFormattedSalePrice',
      showSalePrice: 'getShowFormattedSalePrice',
      savings: 'getFormattedSavings',
      showSavings: 'getShowFormattedSavings',
      saveText: 'getPriceSaveText',
      shippingDisclaimer: 'getShippingDisclaimer',
      showShippingDisclaimer: 'getShowShippingDisclaimer',
      addToCartLoading: 'getAddToCartLoading',
      showBuyAction: 'getHasBuyAction',
      addToCartHref: 'getBuyActionHref',
      addToCartText: 'getBuyActionText',
      showDealerAction: 'getHasDealerAction',
      dealerActionHref: 'getDealerActionHref',
      dealerActionText: 'getDealerActionText',
      expressWarning: 'getExpressWarning',
      showExpressWarning: 'getShowExpressWarning',
      productHeaderDetails: 'getProductHeaderDetails',
      showProductHeaderDetails: 'getShowProductHeaderDetails',
      productAvailability: 'getProductAvailability',
      showProductAvailability: 'getShowProductAvailability',
      vatToolTip: 'getVatToolTip',
      showVatToolTip: 'getShowVatToolTip',
      productLegalText: 'getProductLegalText',
      showProductLegalText: 'getShowProductLegalText',
      productMarketingSummary: 'getProductMarketingSummary',
      showProductMarketingSummary: 'getShowProductMarketingSummary',
      showAddToCartBtn: 'getShowAddToCartBtn',
      widgetMetadata: 'getWidgetMetadata',
      priceData: 'getPriceData',
      locale: 'getLocale',
      affirmWidgetSrc: 'getAffirmWidgetSrc',
      affirmPublicApiKey: 'getAffirmPublicApiKey',
      showAffirmWidget: 'getShowAffirmWidget',
      showKlarnaWidget: 'getShowKlarnaWidget',
    }),
    displayPrice() {
      return this.showPrice && this.showFormattedPrice;
    },
    currencyCode() {
      return window?.utag_data?.currency_code;
    },
  },
  watch: {
    partNumber() {
      this.callInterstitialAccessories();
    },
  },
  mounted() {
    this.callInterstitialAccessories();
    this.$nextTick(() => {
      this.handleLoadingIndicator();
    });
  },
  methods: {
    ...mapActions([
      'setAddToCartLoadingState',
      'callInterstitialAccessories',
      'setIsAffirmScriptLoaded',
    ]),
    handleLoadingIndicator() {
      /* Hide the loading indication after 1 second */
      /* eslint-disable no-return-assign */
      setTimeout(() => this.priceIsLoading = false, 1000);
    },
  },
};
</script>

<template>
  <div
    id="js__product__info"
    class="old__app__product__info"
  >
    <OldTiles
      :title="productName"
      :show-product-name="showProductName"
      :subtitle="productVariation"
      :show-subtitle="showProductVariation"
      :part-number="partNumber"
      :show-part-number="showPartNumber"
    />
    <OldKickerWrapper
      v-if="showKicker"
      id="js__product__info__kicker"
    >
      <OldKicker
        :content="kickerContent"
        :text="kickerText"
      />
    </OldKickerWrapper>
    <ProductAvailability
      v-if="showProductAvailability"
      :content="productAvailability"
    />
    <div
      v-if="priceIsLoading"
      class="app__product__price-loader-wrapper"
    >
      <g-loader type="default" />
    </div>
    <OldPriceWrapper
      v-else
      :price="price"
      :show-price="displayPrice"
      :sale-price="salePrice"
      :show-sale-price="showSalePrice"
      :savings="savings"
      :show-savings="showSavings"
      :translations="saveText"
    />
    <AffirmWidget
      v-if="showAffirmWidget"
      :price-data="priceData"
      :locale="locale"
      :affirm-widget-src="affirmWidgetSrc"
      :affirm-public-api-key="affirmPublicApiKey"
      :show-add-to-cart-btn="showAddToCartBtn"
      :set-is-affirm-script-loaded="setIsAffirmScriptLoaded"
    />
    <KlarnaWrapper
      v-if="showKlarnaWidget"
      id="js__product__info__klarna"
    >
      <OldKlarnaWidget :widget-metadata="widgetMetadata" />
    </KlarnaWrapper>

    <VatTooltip
      v-if="showVatToolTip"
      :content="vatToolTip"
    />
    <OldEmsFilters
      :currency-code="currencyCode"
    />
    <OlColorPicker
      :currency-code="currencyCode"
    />
    <OldCtasWrapper
      :show-add-to-cart-btn="showAddToCartBtn"
      :add-to-cart-text="addToCartText"
      :add-to-cart-href="addToCartHref"
      :add-to-cart-loading="addToCartLoading"
      :set-add-to-cart-loading="setAddToCartLoadingState"
      :show-dealer-btn="showDealerAction"
      :dealer-text="dealerActionText"
      :dealer-href="dealerActionHref"
      :express-warning="expressWarning"
      :show-express-warning="showExpressWarning"
      :currency-code="currencyCode"
    />
    <OldProductFootnote
      :shipping-disclaimer-text="shippingDisclaimer"
      :show-shipping-disclaimer="showShippingDisclaimer"
      :product-header-details="productHeaderDetails"
      :show-product-header-details="showProductHeaderDetails"
      :product-legal-text="productLegalText"
      :show-product-legal-text="showProductLegalText"
      :product-marketing-summary="productMarketingSummary"
      :show-product-marketing-summary="showProductMarketingSummary"
      :part-number="partNumber"
    />
  </div>
</template>

<style lang="scss">
.old__app__product {

  &__info {
    @extend %product-section;
    flex-basis: auto;
    display: block;
    text-align: center;
    max-width: 100%;

    @include productBreakpoint(desktop-s) {
      flex-basis: auto;
      max-width: 50vw;
      text-align: left;
      padding-right: 7%;
      /* stylelint-disable-next-line */
      padding-right: 7vw;
    }

    @include productBreakpoint(desktop-l) {
      padding-right: 10%;
      /* stylelint-disable-next-line */
      padding-right: 10vw;
    }

    a {
      color: $color-garmin-blue-40;
    }
  }

  &__price-loader-wrapper {
    max-width: 2rem;
    margin: 1rem auto 2.5rem auto;

    // Left aligns the loader on larger screens
    @include productBreakpoint(desktop-s) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>
